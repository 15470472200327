const langText = {
  zh: {
    product: '產品',
    aboutUs: '關於我們',
    faq: '常見問題',
    ColorectalCancerScreeningTest: '大腸癌篩檢測試',
    bannerText1: 'ColoClear by Circle是一個可偵測早期患大腸癌風險的非入侵性，在家採樣的大腸癌多靶點糞基因(FIT-DNA)測試。ColoClear旨於讓大腸癌篩檢變得更簡單和便利，助您輕鬆安在家中便可掌握健康。',
    bannerTextLi1: '採用新一代DNA技術，多靶點糞便基因(FIT-DNA)測試技術；技術發展成熟，得到美國預防醫學工作組推薦',
    bannerTextLi2: '5工作天內即可查看測試報告',
    bannerTextLi3: '可從少量糞便樣本中偵測大腸瘜肉及早期患大腸癌的風險',
    bannerTextLi4: '靈敏度高達96%；相當於大腸鏡檢查',
    bannerTextLi5: '獲國家藥品監督管理局批准的大腸癌篩檢檢測',
    getColoClearNow: '立即選購ColoClear',
    learnMore: '了解更多',
    section1_title: '大腸癌篩檢的重要性',
    section1_collapase1_title: '早期篩檢，是您保護自己的第一步',
    section1_collapase1_content: '大腸癌是其中一種最有機會預防的癌症 —如及早發現，高達90%的患者能夠治療。每五個人中就有一個有大腸瘜肉。大部分瘜肉都是良性的，少部分則有演變成癌症的風險。瘜肉檢測和切除可以有效預防大腸癌的發展。',
    section1_collapase2_title: '大腸癌是第二常見的癌症',
    section1_collapase2_content: '2019年，本港錄得5,556宗大腸癌病例，佔整體癌症個案達15.8%。患上大腸癌的風險亦會因年紀增長而顯著上升。',
    section1_collapase3_title: '接近一半的大腸癌病例都在晚期時才發現',
    section1_collapase3_content: '根據2017年的數據，49%的大腸癌病例都在晚期時才發現，令治療非常困難。',
    section1_collapase4_title: '有大腸癌家族或個人病史的人士',
    section1_collapase4_content1: '希望可以了解自己大腸健康狀況及偵測早期患大腸癌的風險的人士',
    section1_collapase4_content2: '有大腸癌家族或個人病史的人士',
    section1_collapase4_content3: '出現慢性便秘或腹脹等腸道症狀的人士。',
    section2_title: 'ColoClear助您掌握大腸健康',
    section3_title: 'ColoClear與其他篩檢方法有甚麼分別？',
    section4_title: '測試流程',
    section4_subTitle: '測試過程簡單易明',
    section6_title: '提交樣本指示',
    section6_subTitle: '預約次日的上門收取樣本服務或自行將樣本送至實驗室。',
    section6_item1_title: '收件服務',
    section6_item1_content1: '請查看以下收件服務的受限地區名單。如您的地址位於受限地區，請自行將樣本送至實驗室室。',
    section6_item1_content2: '按此查看收件服務的受限地區',
    section6_item2_title: '自行提交',
    section6_item2_content1: '香港科學園科技大道西15 號 15W大樓 8樓 803-807室',
    section6_item2_content2: '查看Google地圖',
    section6_item2_content3: '辦公時間：星期一至星期五上午9:00至下午5:00，星期六上午9:00至下午3:00。星期日及公眾假期休息。',
    section8_title: 'ColoClear介紹',
    section9_title: '有疑問嗎？讓我們為您解答！',
    section9_subTitle: '您可查看下列的「常見問題」，或聯絡我們索取更多資訊。',
    section9_bottomButtonText: '更多問題點此',
    section9_bottomButtonText2: '尚有疑問？'
  },
  en: {
    product: 'Our Products',
    aboutUs: 'About Us',
    faq: 'FAQ',
    ColorectalCancerScreeningTest: 'Colorectal Cancer Screening Test',
    bannerText1: 'ColoClear by Circle is a non-invasive, FIT-DNA screening test for detecting early signs of colorectal cancer using at-home sample collection. The test is designed to make colorectal cancer screening simple and convenient, so you can be proactive and in control of your health.',
    bannerTextLi1: 'Utilises next-generation DNA technology, FIT-DNA technology; a well-established technology recommended by the US Preventive Services Task Force',
    bannerTextLi2: 'Results ready within 5 business days',
    bannerTextLi3: 'Advanced DNA-based technology to detect polyps and cancerous cells',
    bannerTextLi4: '96% sensitivity1 compared to colonoscopy',
    bannerTextLi5: 'The only approved colorectal cancer screening test by the National Medical Products Administration (NMPA)1',
    getColoClearNow: 'Get ColoClear Now',
    learnMore: 'Learn More',
    section1_title: 'The importance of colorectal cancer screening',
    section1_collapase1_title: 'Early screening is your first line of defense',
    section1_collapase1_content: 'Colorectal cancer is one of the most preventable cancers - when caught at an early stage up to 90% of cases are treatable. Nearly 1 in 5 individuals have a colorectal polyp. These growths are usually benign but some polyps can grow into cancer. Polyp detection and resection can prevent colorectal cancer from developing.',
    section1_collapase2_title: 'Colorectal cancer is the second most common cancer',
    section1_collapase2_content: 'Hong Kong recorded 5,566 colorectal cancer cases in 2019, making it 15.8% of all cancer cases. The risk of colorectal cancer also increases significantly with age.',
    section1_collapase3_title: 'Almost half of all colorectal cancer cases are late-stage',
    section1_collapase3_content: '49% of colorectal cancer diagnoses in 2017 were late-stage.This makes the cancer very difficult to treat.',
    section1_collapase4_title: 'Who should get tested?',
    section1_collapase4_content1: 'Individuals eager to understand their colon health status and detect colorectal cancer risks early.',
    section1_collapase4_content2: 'Individuals with family or medical history of colorectal cancer.',
    section1_collapase4_content3: 'Individuals with bowel symptoms, such as chronic constipation or bloating.',
    section2_title: 'Colon health made easy with ColoClear',
    section3_title: 'How does ColoClear compare to other screening options?',
    section4_title: 'How it works',
    section4_subTitle: 'Simple steps to get your test done',
    section6_title: 'How to return your sample',
    section6_subTitle: 'Schedule a next-day courier pickup service or drop-off directly at our lab.',
    section6_item1_title: 'Pickup',
    section6_item1_content1: 'Check the list of restricted areas below for courier pickup service. If pickup is not available for your address, please drop off the sample at our lab.',
    section6_item1_content2: 'Click here for areas where pickup service is not available',
    section6_item2_title: 'Drop-off',
    section6_item2_content1: 'Units 803-807, 8/F, Building 15W, No. 15 Science Park West Avenue, Hong Kong Science Park, Pak Shek Kok, NT, Hong Kong ',
    section6_item2_content2: 'Open in Google Maps',
    section6_item2_content3: 'Opening hours: Monday to Friday 9:00am - 5:00pm and Saturday 9:00am - 3:00pm. Closed on Sunday and public holidays.',
    section8_title: 'About ColoClear',
    section9_title: 'Got questions? Get answers.',
    section9_subTitle: 'Check out our frequently asked questions below, or contact us for more information.',
    section9_bottomButtonText: 'Show More',
    section9_bottomButtonText2: 'Have more questions?'
  }
};
export default langText;